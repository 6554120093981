import React from "react";
import { aboutSynergy } from "../Projects/aboutSynergy";

const About: React.FC = () => {
  // const [showFullText, setShowFullText] = useState<boolean>(false);

  // const toggleTextVisibility = () => {
  //   setShowFullText(!showFullText);
  // };

  return (
    <div className="flex mt-20 flex-col gap-5">
      <h2 className="text-center headings text-4xl sm:text-5xl font-serif font-bold tracking-widest uppercase text-gray-800">
        About Us
      </h2>
      <p className="mx-10 py-5  md:mx-20 text-justify text-sm sm:text-2xl text-gray-700 tracking-wider font-sans">
        {aboutSynergy?.about}
      </p>
    </div>
  );
};

export default About;
