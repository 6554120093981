import React, { useEffect, useState } from "react";

const ToTop: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", function asdf() {
      if (window.scrollY < 100) {
        setScrolled(true);
      } else setScrolled(false);
    });
  }, [scrolled]);

  const scrolltoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <button
        id="to-top-button"
        onClick={scrolltoTop}
        title="Go To Top"
        className={`${
          scrolled ? "hidden" : "block"
        } fixed z-50 bottom-8 right-8 border-0 h-12 w-12 sm:w-16 sm:h-16 rounded-full drop-shadow-md bg-[dodgerblue] text-white text-3xl font-bold`}
      >
        &uarr;
      </button>
    </>
  );
};

export default ToTop;
