import { Card } from "@material-tailwind/react";
import { teamMemberDetails } from "../Team/TeamData";
import Marquee from "react-fast-marquee";

const Teams = () => {
  return (
    <div className=" py-14 mt-5">
      <div className="py-3 text-center text-slate-800 ">
        <h2 className="text-4xl sm:text-5xl headings text-gray-800 uppercase tracking-widest  ">
          Meet Our Team
        </h2>
      </div>
      <div className="text-slate-700 text-center ">
        <p className="text-base sm:text-2xl sm:px-20 py-5  font-sans">
          Presenting our outstanding teams, fueled by passion and expertise,
          poised to collaborate and
          <br /> transform your vision into reality.
        </p>
      </div>
      {/* <div className=" grid grid-cols-4 px-8 py-5 msm:grid-cols-1  msm:px-12  md:grid-cols-2  "> */}
      <Marquee className="mt-10">
        {teamMemberDetails?.map((img_data) => {
          const { id, imgSrc, name, designation } = img_data;
          return (
            <div key={id} className="px-5 py-5">
              <Card
                placeholder=""
                className="h-auto cursor-pointer  w-[250px] bg-gray-50 shadow-lg transform hover:scale-110 transition-transform duration-300"
              >
                <div className="px-6 py-2">
                  <Card
                    placeholder=""
                    className="h-[200px] rounded-full w-[200px]"
                  >
                    <img
                      className="h-[200px] shadow-2xl w-[200px] rounded-full"
                      src={imgSrc}
                      alt=""
                    />
                  </Card>
                </div>
                <div className="text-gray-700 mt-3 items-center justify-center text-center">
                  <h1>{name}</h1>
                  <p>{designation}</p>
                </div>
              </Card>
            </div>
          );
        })}
      </Marquee>
    </div>

    // </div>
  );
};

export default Teams;
