import React, { useState } from "react";

import { FaAngleDown, FaAngleRight, FaBars } from "react-icons/fa";
import { navItems } from "../NavContents/navcontent";
import { Link } from "react-router-dom";

const NavBars = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [projectDropdown, setProjectDropdown] = useState<boolean>(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const toggleProjectDropdown = (): void => {
    setProjectDropdown((prev) => !prev);
  };

  return (
    <nav className="bg-white shadow-2xl  py-2 px-7 lg:h-[80px] h-auto sticky top-0 z-10  md:flex lg:justify-between">
      <div className="font-bold text-2xl  cursor-pointer">
        <Link to="/">
          <img
            src="/images/logo192.png"
            className="rounded-xl w-[150px] sm:w-[199px] logo_section animate-pulse"
            alt=""
          />
        </Link>
      </div>

      <>
        <div className="lg:hidden absolute right-6 top-4 flex items-center pr-2">
          <button
            type="button"
            className="relative bg-gray-800 p-1 transition-all duration-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            onClick={toggleMobileMenu}
          >
            <FaBars className="text-white text-2xl " />
          </button>
        </div>

        <div className="hidden transition-all  duration-300 lg:flex ml-6">
          {navItems.map((currentNavItems, i) => {
            const { item, links } = currentNavItems;

            return (
              <li
                key={i}
                className="md:my-2 my-[95px] md:text-sm uppercase ml-4 cursor-pointer flex flex-col  justify-center items-center"
              >
                {item === "Services" ? (
                  <span className="nav_menu tracking-widest">
                    <a href="#services">Services </a>
                  </span>
                ) : (
                  <Link to={links}>
                    <div
                      className="text-black
                  cursor-pointer relative p-1 tracking-widest"
                    >
                      <span className="nav_menu">
                        {item === "Projects" ? (
                          <span
                            className="flex items-center"
                            onClick={toggleProjectDropdown}
                          >
                            Projects
                            {projectDropdown ? (
                              <FaAngleDown className="text-xl" />
                            ) : (
                              <FaAngleRight className="text-xl" />
                            )}
                          </span>
                        ) : (
                          item
                        )}
                      </span>
                    </div>
                  </Link>
                )}
              </li>
            );
          })}
          {projectDropdown && (
            <li className="md:my-2 my-[95px] fixed top-12 right-48 uppercase ml-3 cursor-pointer flex flex-col   justify-center items-center">
              <div
                className="bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4"
                id="dropdown"
              >
                <ul className="py-1" aria-labelledby="dropdown">
                  <li>
                    <Link
                      to="/completed"
                      className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                    >
                      Completed
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/underway"
                      className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                    >
                      Underway
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          )}
        </div>

        {/* for mobile devices */}
        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } lg:hidden h-auto  px-2 pb-3 mt-2 sm:ml-20 transition duration-300 max-h-full`}
        >
          <div className="flex flex-col space-y-4">
            {navItems.map((currentNavItems, i) => {
              const { id, item, links } = currentNavItems;

              return (
                <li
                  key={id}
                  className="md:my-2 flex flex-col uppercase  justify-center items-center"
                >
                  <div className="text-black resize_text cursor-pointer  relative p-1 tracking-wider">
                    {item === "Services" ? (
                      <span className="nav_menu ">
                        <a className="text-sm tracking-widest" href="#services">
                          Services
                        </a>
                      </span>
                    ) : (
                      <Link to={links}>
                        <div
                          className="text-black
                  cursor-pointer relative p-1 tracking-widest"
                        >
                          <span className="nav_menu ">
                            {item === "Projects" ? (
                              <span
                                className="flex items-center text-sm"
                                onClick={toggleProjectDropdown}
                              >
                                Projects
                                {projectDropdown ? (
                                  <FaAngleDown className="text-xl" />
                                ) : (
                                  <FaAngleRight className="text-xl" />
                                )}
                              </span>
                            ) : (
                              // item
                              <span className="nav_menu text-sm ">{item}</span>
                            )}
                          </span>
                        </div>
                      </Link>
                    )}
                    {/* <Link to={links}>
                      <div
                        className="text-black
                  cursor-pointer relative p-1 tracking-widest"
                      >
                        <span className="nav_menu">
                          {item === "Projects" ? (
                            <span
                              className="flex items-center"
                              onClick={toggleProjectDropdown}
                            >
                              Projects
                              {projectDropdown ? (
                                <FaAngleDown className="text-xl" />
                              ) : (
                                <FaAngleRight className="text-xl" />
                              )}
                            </span>
                          ) : (
                            item
                          )}
                        </span>
                      </div>
                    </Link> */}
                  </div>
                </li>
              );
            })}
            {projectDropdown && (
              <li className="md:my-2 my-[95px] dropdown_menu  fixed top-52 uppercase ml-3 cursor-pointer flex flex-col  justify-center items-center">
                <div
                  className="bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4"
                  id="dropdown"
                >
                  <ul className="py-1" aria-labelledby="dropdown">
                    <li>
                      <Link
                        to="/completed"
                        className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                      >
                        Completed
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/underway"
                        className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                      >
                        Underway
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
          </div>
        </div>
      </>
    </nav>
  );
};

export default NavBars;
