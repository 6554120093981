export const navItems: {
  id: number;
  item: string;
  links: string;
}[] = [
  {
    id: 1,
    item: "Home",
    links: "/",
  },

  {
    id: 2,
    item: "Services",
    links: "",
  },

  {
    id: 5,
    item: "Gallery",
    links: "/gallery",
  },
  {
    id: 6,
    item: "Projects",
    links: "",
  },
  {
    id: 8,
    item: "Career",
    links: "/career",
  },
  {
    id: 9,
    item: "Contact",
    links: "/contact",
  },
];
