import React, { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { img_container } from "../bannerImg/banner";

const Banner: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const imageRef = useRef<HTMLImageElement | null>(null);

  // swipe left carosel function
  const swipeLeft = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? img_container.length - 1 : prevIndex - 1
    );
  };

  // swipe right carousel function
  const swipeRight = () => {
    setIndex((prevIndex) =>
      prevIndex === img_container.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(() => Math.floor(Math.random() * img_container.length));
    }, 4000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="relative">
      <div
        className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"
        style={{
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5))",
        }}
      ></div>
      <img
        src={img_container[index].src}
        ref={imageRef}
        alt=""
        className="w-full h-[350px] lg:h-[85vh]"
      />
      <div className="absolute bottom-7 left-1/2 transform -translate-x-1/2">
        <h3 className="text-white text-sm md:text-2xl tracking-wider lg:text-4xl">
          {img_container[index].label}
        </h3>
      </div>
      <div className="buttons hidden sm:block">
        <button className="absolute  p-2 rounded-full bg-[#F5FEFD] top-[265px]">
          <FaAngleLeft onClick={swipeLeft} className="md:text-[40px]" />
        </button>
        <button className="absolute rounded-full bg-[#F5FEFD] p-2 top-[265px] right-3">
          <FaAngleRight onClick={swipeRight} className="md:text-[40px]" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
