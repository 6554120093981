import RoutePath from "./Routes/RoutePath";

const App = () => {
  return (
    <>
      <RoutePath />
    </>
  );
};

export default App;
