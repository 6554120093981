export const img_container = [
  {
    id: 1,
    src: "/images/l.jpg",
    label: "Chief Minister's Office Building, Pokhara, State -4",
  },

  {
    id: 2,
    src: "/images/a.jpg",
    label: "Elevation of Hotel Manaslu",
  },

  {
    id: 3,
    src: "/images/b.jpeg",
    label: "Budhanilkanth School's Accommodation Facility for Female Students",
  },

  {
    id: 4,
    src: "/images/o.jpg",
    label: "Health Science Educational Facility",
  },

  {
    id: 5,
    src: "/images/g.jpeg",
    label: "Immigration Department at Tribhuvan International Airport",
  },

  {
    id: 6,
    src: "/images/k.jpeg",
    label: "Swing Hotel in Pokhara",
  },
];
