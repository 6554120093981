import React from "react";
import NavBars from "./NavBars";
import Footer from "./Footer";
import { FaSmile } from "react-icons/fa";

const Career: React.FC = () => {
  return (
    <>
      <NavBars />
      <div className="flex justify-center flex-col items-center h-[100vh]">
        <FaSmile className="text-center text-5xl text-yellow-900" />
        <h2 className="text-3xl tracking-wider font-serif">
          Currently, there are no available positions.
        </h2>
      </div>
      <Footer />
    </>
  );
};

export default Career;
