export const gallery: {
  id: number;
  src: string;
}[] = [
  {
    id: 1,
    src: "/images/a.jpg",
  },
  {
    id: 2,
    src: "/images/b.jpeg",
  },
  {
    id: 3,
    src: "/images/c.jpeg",
  },
  {
    id: 4,
    src: "/images/d.jpg",
  },
  {
    id: 5,
    src: "/images/e.jpg",
  },
  {
    id: 6,
    src: "/images/f.jpg",
  },
  {
    id: 7,
    src: "/images/g.jpeg",
  },
  {
    id: 8,
    src: "/images/i.jpg",
  },
  {
    id: 9,
    src: "/images/k.jpeg",
  },
  {
    id: 10,
    src: "/images/l.jpg",
  },
  {
    id: 11,
    src: "/images/n.jpeg",
  },
  {
    id: 12,
    src: "/images/o.jpg",
  },
  {
    id: 13,
    src: "/images/p.jpg",
  },
  {
    id: 14,
    src: "/images/eq1.jpg",
  },
  {
    id: 15,
    src: "/images/eq2.jpg",
  },
  {
    id: 16,
    src: "/images/eq3.jpg",
  },
  {
    id: 17,
    src: "/images/eq4.jpg",
  },
  {
    id: 18,
    src: "/images/eq5.jpg",
  },
  {
    id: 19,
    src: "/images/eq6.jpg",
  },
];
