export const teamMemberDetails = [
  {
    id: 1,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/09/arjun-sir.jpeg",
    name: "Er. Arjun Bhattrai",
    designation: "Managing Director",
  },

  {
    id: 2,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/11/IMG_20181101_081006.jpg",
    name: "Mrs. Asmita Pokhrel",
    designation: "Finance Director",
  },

  {
    id: 3,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/09/Photo.jpeg",
    name: "Mr. Subash Bhattrai",
    designation: "Finance Manager",
  },

  {
    id: 4,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/11/manoj-pp.jpg",
    name: "Mr. Manoj Kc",
    designation: "Finance Consultatant",
  },

  {
    id: 5,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/09/hari.jpeg",
    name: "Mr. Hari Prasad Bhattrai",
    designation: "Project Manager",
  },

  {
    id: 6,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/11/IMG_20181101_110008.jpg",
    name: "Er. Indra Prasad Khanal",
    designation: "Project Manager",
  },

  {
    id: 7,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/11/IMG_20181031_163456-e1541037480812.jpg",
    name: "Er. Sandip Sodari",
    designation: "Project Engineer",
  },

  {
    id: 8,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/09/0.jpg",
    name: "Er. Ranindra Maharjan",
    designation: "Project Engineer",
  },

  {
    id: 9,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/09/arjun-sir.jpeg",
    name: "Er. Arjun Bhattrai",
    designation: "Managing Director",
  },

  {
    id: 10,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2018/11/IMG-a8939210ff181ea8eeae036168726305-V.jpg",
    name: "Er. Yugal Duwal",
    designation: "Project Engineer",
  },

  {
    id: 11,
    imgSrc: "/images/user2.png",
    name: "Er. Bibek Kc",
    designation: "Project Engineer",
  },

  {
    id: 12,
    imgSrc: "/images/user2.png",
    name: "Er. Santosh Kumar Chaudhary",
    designation: "Civil Engineer",
  },

  {
    id: 13,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2019/02/IMG_20190226_163018.jpg",
    name: "Mr. Hemanta Acharya",
    designation: "Procurement In-Charge",
  },

  {
    id: 14,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2020/01/FB_IMG_1579755837064.jpg",
    name: "Noroj Bhurtel",
    designation: "Accountant",
  },

  {
    id: 15,
    imgSrc:
      "https://www.synergybuildersnepal.com/wp-content/uploads/2020/01/received_115613903103712.jpeg",
    name: "Ojaswi Rijal",
    designation: "Account Asst.",
  },

  {
    id: 16,
    imgSrc: "/images/user2.png",
    name: "Mr. Madhav Poudel",
    designation: "Site Supervisor",
  },

  {
    id: 17,
    imgSrc: "/images/user2.png",
    name: "Mr. Suresh Chaudhary",
    designation: "Site Supervisor",
  },

  {
    id: 18,
    imgSrc: "/images/user2.png",
    name: "Jhabi Lal Pandey",
    designation: "",
  },
];
