export const working = [
  {
    id: 1,
    projectStakeHolder: "DUDBC Division office Saptari ,Rajbiraj",
    projectName: "Construction Work of Buspark Rajbiraj , Municipality",
    projectStatus: "Underway",
  },

  {
    id: 2,
    projectStakeHolder: "Department of Road Division Road Office Butwal",
    projectName:
      "Office Building Construction work for Division Road Office Butwal, Rupendehi",
    projectStatus: "Underway",
  },

  {
    id: 3,
    projectStakeHolder: "Tilottama Municipality",
    projectName: "Construction of Municipality Office Building",
    projectStatus: "Underway",
  },

  {
    id: 4,
    projectStakeHolder: "Department Of Agriculture",
    projectName:
      "Construction of office Building Training Building and Boundary Wall at Kirtipur, Kathmandu",
    projectStatus: "Underway",
  },

  {
    id: 5,
    projectStakeHolder:
      "Department of Urban Development and Building Construction Project Office of Urban Development and Building Construction",
    projectName:
      "Construction of Human Nagar Cold Store at Hanuman nagar Kankalini Municipality, Saptary",
    projectStatus: "Underway",
  },

  {
    id: 6,
    projectStakeHolder: "Triten Norbutse Institution at Ichangu, Kathmandu",
    projectName: "Construction of Main Temple(Monastry) at Kathmandu",
    projectStatus: "Underway",
  },

  {
    id: 7,
    projectStakeHolder: "Pokhara Swing and Retreat Pvt Ltd",
    projectName: "Construction of Pokhara Swing and Re-treat at Pokhara",
    projectStatus: "Underway",
  },

  {
    id: 8,
    projectStakeHolder: "Rastriya Banijya Bank Limited",
    projectName:
      "Construction of Branch Office Building of Rastriya Banijya Bank Limited at Bhairahawa, Rupandehi",
    projectStatus: "Underway",
  },

  {
    id: 9,
    projectStakeHolder: "Bhairahawa Garden Resort at Bhairahawa, Rupandehi",
    projectName: "Construction of Hotel Building at Bhairahawa, Rupandehi",
    projectStatus: "Underway",
  },

  {
    id: 10,
    projectStakeHolder:
      "Syuchatar Saving and Credit Co-Operative Ltd at Syuchatar, Kathmandu",
    projectName:
      "Construction of Office,Commerical Building at Syuchatar, Kathmandu",
    projectStatus: "Underway",
  },

  {
    id: 11,
    projectStakeHolder: "	DUDBC Federal Project implementation Unit Kaski",
    projectName:
      "Construction of Electric Crematorium at Buddha Arghu Sadan, Pokhara",
    projectStatus: "Underway",
  },
];
