import React from "react";
import { FaFacebook } from "react-icons/fa";
import { MdOutlineSettingsPhone } from "react-icons/md";
import { navItems } from "../NavContents/navcontent";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800">
      <div className=" xl:mx-20 w-full max-w-screen-xl ">
        <h2 className="text-gray-500 text-3xl  sm:text-4xl px-4  py-20 tracking-wide font-serif">
          We're Synergy Builders
          <br />
          Building Dreams, Crafting Reality.
        </h2>

        {/* starts here */}

        <div className="sm:grid md:grid-cols-2  px-4 py-6 lg:py-8 xl:flex justify-between">
          <div className="mb-6">
            <h2 className="mb-6 text-lg font-semibold text-gray-500 uppercase dark:text-white">
              Synergy Builders
            </h2>
            <div className="flex flex-col text-gray-500 tracking-wider ">
              <span>Kulehwor, Kathmandu, Nepal</span>
              <span>Phone: +977 01-4282561</span>
              <span>Email: synergybuildersnepal@gmail.com</span>
            </div>
          </div>

          <div className="mb-6 lg:ml-20">
            <h2 className="mb-6  text-lg font-semibold text-gray-500 uppercase dark:text-white">
              Quick Links
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {navItems?.map((items) => (
                <li key={items?.id} className="mb-4">
                  <Link to={items?.links} className=" hover:underline">
                    {items?.item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-6 lg:ml-20">
            <h2 className="mb-6 text-lg font-semibold text-gray-500 uppercase dark:text-white">
              Help center
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-4">
                <Link
                  target="_blank"
                  to="https://www.facebook.com/synergy.builders.nepal/"
                  className="hover:underline flex items-center gap-3"
                >
                  <FaFacebook className="text-lg" />
                  Facebook
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/contact"
                  className="hover:underline flex items-center gap-3"
                >
                  <MdOutlineSettingsPhone className="text-lg" />
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="mb-6 lg:ml-20">
            <h2 className="mb-6 text-lg font-semibold text-gray-500 uppercase dark:text-white">
              Legal
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  Licensing
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* ends here */}
      </div>
      <div className=" bg-white py-4 dark:bg-gray-500 flex items-center sm:justify-around justify-between sm:px-10 px-2">
        <span className=" text-sm sm:text-lg text-gray-700 dark:text-gray-300 sm:text-center">
          {/* © 2023 <Link to="https://flowbite.com/">Flowbite™</Link>. All Rights
          Reserved. */}
          <span className=" text-sm lg:text-2xl ">&copy;</span> Synergy
          Builders. All Rights Reserved
        </span>
        <span className=" text-sm lg:text-lg text-gray-700">
          Designed By: CS Sewa
        </span>
      </div>
    </footer>
  );
};

export default Footer;
