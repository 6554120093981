import NavBars from "./NavBars";
import Banner from "./Banner";
import About from "./About";
import OurServices from "./OurServices";
import Teams from "./Teams";
import Footer from "./Footer";
import ToTop from "./ToTop";

const Home: React.FC = () => {
  return (
    <>
      <NavBars />

      <Banner />

      <About />
      <section id="services">
        <OurServices />
      </section>

      <Teams />

      <Footer />
      <ToTop />
    </>
  );
};

export default Home;
