import React from "react";
import { servicesData } from "../services/servicesdata";

const OurServices = () => {
  return (
    <div className="mt-20  py-20 px-5   bg-[#f0efef]">
      <h2 className="text-center headings text-4xl  sm:text-5xl font-bold tracking-widest uppercase text-gray-800">
        Our Services
      </h2>

      <div
        id="services"
        className="mt-10 cursor-pointer sm:grid md:grid-cols-2 xl:grid-cols-3 "
      >
        {servicesData?.map((data) => (
          <div
            key={data?.id}
            className="mt-16 sm:m-[5px] cards services_div bg-blue-gray-50  transition duration-[420ms] ease-in transform sm:hover:scale-100 py-5 rounded-lg shadow-lg  "
          >
            <div className="flex mx-10 gap-4   ">
              <span className=" text-3xl sm:text-4xl text-gray-800 services_icon ">
                {" "}
                {data?.icon}
              </span>
              <h2 className=" text-2xl sm:text-3xl text-[green]">
                {data?.heading}
              </h2>
            </div>
            <p className="text-lg sm:text-xl text-gray-700 mx-10 font-sans">
              {data?.details}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
