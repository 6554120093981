import React, { useState } from "react";
import NavBars from "../components/NavBars";
import { working } from "../Projects/Working";
import Pagination from "../pagination/Pagination";
import Footer from "../components/Footer";
import DataTable from "react-data-table-component";
const Underway = () => {
  const columns = [
    {
      name: "S.N.",
      selector: (row: any) => row.id,
      width: "100px",
    },
    {
      name: " Project Name",
      selector: (row: any) => row.projectName,
      width: "700px",
    },
    {
      name: "Client Name",
      selector: (row: any) => row.projectStakeHolder,
      width: "500px",
    },
    {
      name: "Project Status",
      selector: (row: any) => row.projectStatus,
      width: "200px",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: any) => true,
      style: {
        cursor: "pointer",
      },
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>
      <NavBars />
      <DataTable
        columns={columns}
        data={working}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
        paginationComponentOptions={paginationOptions}
        conditionalRowStyles={conditionalRowStyles}
      />

      <div className="mt-4">
        <Footer />
      </div>
    </>
  );
};

export default Underway;
