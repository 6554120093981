import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { gallery } from "../GalleryItems/galleryImages";
import NavBars from "./NavBars";
import Footer from "./Footer";

const Gallery: React.FC = () => {
  return (
    <>
      <NavBars />
      <div className="m-2 sm:grid grid-cols-2 md:grid-cols-3 gap-3">
        <PhotoProvider>
          {gallery.map((item, index) => (
            <PhotoView key={index} src={item?.src}>
              <img
                className="h-[280px] mt-2 sm:mt-0 lg:h-[400px] lg:w-[700px] cursor-pointer max-w-full rounded-lg"
                src={item?.src}
                alt=""
              />
            </PhotoView>
          ))}
        </PhotoProvider>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
