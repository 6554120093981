import { FaHelmetSafety } from "react-icons/fa6";
import { MdConstruction } from "react-icons/md";
import {
  FaDraftingCompass,
  FaSign,
  FaPoll,
  FaPencilRuler,
} from "react-icons/fa";

export const servicesData = [
  {
    id: 1,
    icon: <FaHelmetSafety />,
    heading: "Construction Works",
    details:
      " Construct massive infrastructure, erect buildings, develop complexes, and build expansive shopping malls.",
  },

  {
    id: 2,
    icon: <MdConstruction />,
    heading: "Infrastructure Design",
    details:
      " Create interior and exterior designs for residential structures, encompassing the overall design of the entire house.",
  },

  {
    id: 3,
    icon: <FaDraftingCompass />,
    heading: "Technical Sketch",
    details:
      "Produce engineering drawings that provide clients with a comprehensive overview of their projects.",
  },

  {
    id: 4,
    icon: <FaSign />,
    heading: "Expense Projection",
    details:
      "Anticipate and plan for the total project expenditures in advance, then align your efforts accordingly.",
  },

  {
    id: 5,
    icon: <FaPoll />,
    heading: "Mapping and Appraisal",
    details:
      "Thoroughly conducting surveys prior to commencing projects can assist in both project initiation and asset valuation",
  },

  {
    id: 6,
    icon: <FaPencilRuler />,
    heading: "Provider and Oversight",
    details:
      "Supplies highly skilled personnel and necessary resources following a site inspection.",
  },
];
