import { MdOutlinePhoneForwarded } from "react-icons/md";
import NavBars from "../components/NavBars";
import Footer from "../components/Footer";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <NavBars />
      <div className="container my-24 mx-auto md:px-6">
        <section className="mb-32">
          <div className="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://mdbcdn.b-cdn.net/img/new/textures/full/284.jpg')]"></div>
          <div className="container px-6 md:px-12">
            <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
              <div className="lg:flex justify-center items-center">
                <section>
                  <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                      Contact Us
                    </h2>
                    <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                      Got a technical issue? Want to send feedback about a beta
                      feature? Need details about our Business plan? Let us
                      know.
                    </p>
                    <form action="#" className="space-y-8">
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          placeholder="John doe"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          placeholder="name@gmail.com"
                          required
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="subject"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="subject"
                          className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          placeholder="Let us know how we can help you"
                          required
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="message"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                        >
                          Your message
                        </label>
                        <textarea
                          id="message"
                          rows={6}
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Leave a comment..."
                        ></textarea>
                      </div>
                      <button className="bg-blue-500 capitalize hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full">
                        send message
                      </button>
                    </form>
                  </div>
                </section>
                <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                  <div className="flex flex-wrap">
                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <MdOutlinePhoneForwarded className="text-2xl" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 sm:text-2xl font-bold dark:text-white">
                            Technical support
                          </p>
                          <p className="text-neutral-500 sm:text-xl dark:text-neutral-200">
                            synergybuildersnepal@gmail.com
                          </p>
                          <p className="text-neutral-500 text-xl dark:text-neutral-200">
                            +977 01-4282561
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-12 ml-2 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:mb-12 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="align-start flex">
                        <div className="shrink-0">
                          <div className="inline-block sm:ml-2 rounded-md bg-primary-100 p-4 text-primary">
                            <FaFacebook className="text-2xl" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 sm:text-2xl font-bold dark:text-white">
                            Social Media
                          </p>
                          <p className="text-neutral-500 hover:underline text-xl dark:text-neutral-200">
                            <Link
                              to="https://www.facebook.com/synergy.builders.nepal/"
                              target="_blank"
                            >
                              Facebook
                            </Link>
                          </p>
                          {/* <p className="text-neutral-500 text-xl dark:text-neutral-200">
                            +1 234-567-89
                          </p> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-12 xl:w-6/12">
                      <div className="align-start flex">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <IoBugSharp className="text-2xl" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-white">
                            Bug report
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            bugs@example.com
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            +1 234-567-89
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="max-w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.9984244266625!2d85.29421117635414!3d27.686443576194616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191c8f2beb51%3A0xb227ed0737eb6868!2sSynergy%20Builders%20P.%20Ltd.!5e0!3m2!1sen!2snp!4v1704434236384!5m2!1sen!2snp"
          style={{ width: "100%" }}
          height="500"
          title="map"
          // allowfullscreen={true}
          loading="lazy"
          // referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
