export const completedTableItems = [
  {
    id: 1,
    projectStakeHolder: "Vyas Municipality Office , Tanahun",
    projectName:
      "Construction of Vyas Multi-Purpose Building , Damauli, Tanahun",
    projectStatus: "Completed",
  },

  {
    id: 2,
    projectStakeHolder: "Bank of Kathmandu Limited , Kamaladi , Kathmandu",
    projectName:
      "Construction of Branch Office Building of Bank of Kathmandu at Balaju, Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 3,
    projectStakeHolder:
      "	CTEVT Skill Development Project (SDP) Baneshwor, Kathmandu",
    projectName:
      "	Construction of Classroom and Laboratory Block at a) Rapti Technical School Lalmatiya Dang b) Seti Technical School , Dipayal, Doti C) Bheri Technical School Nepalgunj, Banke",
    projectStatus: "Completed",
  },

  {
    id: 4,
    projectStakeHolder:
      "Council for Technical Education and Vocational Training Sanothimi , Bhakthpur",
    projectName:
      "Construction of Academic Building of School of Health Science , Bharatpur, Chitwan",
    projectStatus: "Completed",
  },

  {
    id: 5,
    projectStakeHolder:
      "Council for Technical Education and Vocational Training",
    projectName:
      "Construction of academic Building above Plinth Level of Gorkha Polytechnic Institute , Palungtar, Gorkhan",
    projectStatus: "Completed",
  },

  {
    id: 6,
    projectStakeHolder:
      "Deparrtment of Urban Development and Building Construction Division Office Kaski",
    projectName:
      "Construction of Labour Office Building Ratnapur-7, Pokhara ,Kaski",
    projectStatus: "Completed",
  },

  {
    id: 7,
    projectStakeHolder: "PEA Association Pvt Ltd Thapathali Kathmandu",
    projectName:
      "Construction of Academic Building is Located at Thapathali, Jyacess Marga Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 8,
    projectStakeHolder: "New Summit College Baneshwor , kathmandu",
    projectName:
      "Construction of Academic Building Located at KMC 34, Old Baneshwor, Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 9,
    projectStakeHolder: "Apollo International College , Baneshwor, Kathmandu",
    projectName: "construction of College Building at Baneshwor, kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 10,
    projectStakeHolder: "Premier International School , Khumaltar, Lalitpur",
    projectName: "Construction of Multipurpose Hall at Khumaltar, Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 11,
    projectStakeHolder:
      "	Council for Technical Education and Vocational Training , Sanothimi Bhaktpur",
    projectName:
      "Construction of Office & Security Guard Building , Shed, Reservior Tank &Site Development work of Construction of Exam Controller Office Building",
    projectStatus: "Completed",
  },

  {
    id: 12,
    projectStakeHolder:
      "	CTEVT Balaju School of Engineering and Technology Balaju kathmandu",
    projectName: "Construction of Multipurpose Hall at Khumaltar, Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 13,
    projectStakeHolder:
      "Council for Technical Education and Vocational Training , Sanothimi Bhaktpur",
    projectName:
      "Construction of Examination Controller Office Building at Sanothimi Bhaktpur",
    projectStatus: "Completed",
  },

  {
    id: 14,
    projectStakeHolder: "Sanima Bank Limited",
    projectName: "Construction of Branch Office Building at Inaruwa Sunasari",
    projectStatus: "Completed",
  },

  {
    id: 15,
    projectStakeHolder: "Department of Agriculture at Hariharbhawan, Lalitpur",
    projectName:
      "Construction of Office Builduing , Training Building and Boundry wall at kirtipur , Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 16,
    projectStakeHolder: "Max International Putalisadak, Kathmandu",
    projectName:
      "Construction of Commerical Building for MAX International at Putalisadak, kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 17,
    projectStakeHolder:
      "Council for Technical Education and Vocational Training Sanothimi Bhaktpur",
    projectName:
      "Construction of Academic Block and Various Work of Ramechhap Polytechnic Insititute Manthali, Ramechhap",
    projectStatus: "Completed",
  },

  {
    id: 18,
    projectStakeHolder: "Metropolitan Police Range Teku, kathmandu",
    projectName:
      "Re- Construction of Barrack Block Septic Tank and Soak Pit Metropolitan Police Circle Kirtipur, Kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 19,
    projectStakeHolder: "Nepal Academy of Tourism and Hotel Management",
    projectName:
      "Construction of Academic Building at Ravibhawan, Kalimati , kathmandu",
    projectStatus: "Completed",
  },

  {
    id: 20,
    projectStakeHolder:
      "Riverside Royal Garden Resort Pvt Ltd Patihani, Chitwan",
    projectName:
      "Construction of Hotel Building of Riverside Royel Garden Resort , Patihani, Chitwan",
    projectStatus: "Completed",
  },

  {
    id: 21,
    projectStakeHolder:
      "Council for Technical Education and Vocational Training Sanothimi Bhaktpur",
    projectName:
      "Construction of Academic lock above Ground floor Jaya Kishan Polytechnic Institute Dharhari, Rauthad",
    projectStatus: "Completed",
  },

  {
    id: 22,
    projectStakeHolder:
      "Department of Road Project Directorate (ADB) Bishalnagar, kathmandu",
    projectName:
      "Earthquake Emergency Assistance Project (EEAP)(ADB Loan No 3260- NEP)",
    projectStatus: "Completed",
  },

  {
    id: 23,
    projectStakeHolder: "Department of Immigration Kalikasthan, kathmandu",
    projectName:
      "Construction Work of Immigration Chambers and Interior of the Immigration Office at Tribhuvan International Airport",
    projectStatus: "Completed",
  },

  {
    id: 24,
    projectStakeHolder:
      "Balaju Industrial District Management Office at Balaju, Kathmandu",
    projectName: "Construction of Commerical Complex (Phase II)",
    projectStatus: "Completed",
  },
];
