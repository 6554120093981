import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home";

import Worked from "../RoutesConnection/Worked";
import Gallery from "../components/Gallery";
import Contact from "../RoutesConnection/Contact";
import Underway from "../RoutesConnection/Underway";
import Career from "../components/Career";

const RoutePath = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/completed" element={<Worked />} />
      <Route path="/underway" element={<Underway />} />
      <Route path="/career" element={<Career />} />
      <Route path="/gallery" element={<Gallery />} />
    </Routes>
  );
};

export default RoutePath;
